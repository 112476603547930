var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_c('header',[_c('h1',{staticClass:"gl-font-size-h1"},[_vm._v(_vm._s(_vm.$options.i18n.securityConfiguration))])]),_vm._v(" "),(_vm.canUpgrade)?_c('user-callout-dismisser',{attrs:{"feature-name":"security_configuration_upgrade_banner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dismiss = ref.dismiss;
var shouldShowCallout = ref.shouldShowCallout;
return [(shouldShowCallout)?_c('upgrade-banner',{on:{"close":dismiss}}):_vm._e()]}}],null,false,1619638184)}):_vm._e(),_vm._v(" "),_c('gl-tabs',{attrs:{"content-class":"gl-pt-6"}},[_c('gl-tab',{attrs:{"data-testid":"security-testing-tab","title":_vm.$options.i18n.securityTesting}},[_c('section-layout',{attrs:{"heading":_vm.$options.i18n.securityTesting},scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.latestPipelinePath)?_c('p',{staticClass:"gl-line-height-20",attrs:{"data-testid":"latest-pipeline-info-security"}},[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.securityTestingDescription},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.latestPipelinePath}},[_vm._v(_vm._s(content))])]}}],null,false,4220286026)})],1):_vm._e(),_vm._v(" "),(_vm.canViewCiHistory)?_c('p',[_c('gl-link',{attrs:{"data-testid":"security-view-history-link","href":_vm.gitlabCiHistoryPath}},[_vm._v(_vm._s(_vm.$options.i18n.configurationHistory))])],1):_vm._e()]},proxy:true},{key:"features",fn:function(){return _vm._l((_vm.augmentedSecurityFeatures),function(feature){return _c('feature-card',{key:feature.type,staticClass:"gl-mb-6",attrs:{"feature":feature}})})},proxy:true}])})],1),_vm._v(" "),_c('gl-tab',{attrs:{"data-testid":"compliance-testing-tab","title":_vm.$options.i18n.compliance}},[_c('section-layout',{attrs:{"heading":_vm.$options.i18n.compliance},scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.latestPipelinePath)?_c('p',{staticClass:"gl-line-height-20",attrs:{"data-testid":"latest-pipeline-info-compliance"}},[_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.securityTestingDescription},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.latestPipelinePath}},[_vm._v(_vm._s(content))])]}}],null,false,4220286026)})],1):_vm._e(),_vm._v(" "),(_vm.canViewCiHistory)?_c('p',[_c('gl-link',{attrs:{"data-testid":"compliance-view-history-link","href":_vm.gitlabCiHistoryPath}},[_vm._v(_vm._s(_vm.$options.i18n.configurationHistory))])],1):_vm._e()]},proxy:true},{key:"features",fn:function(){return _vm._l((_vm.augmentedComplianceFeatures),function(feature){return _c('feature-card',{key:feature.type,staticClass:"gl-mb-6",attrs:{"feature":feature}})})},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }